import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Resources = () => (
  <Layout>
    <Seo title="Resources" />
  </Layout>
)

export default Resources
